import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "destination"]
  static values = {
    disableIfParentHidden: {
      type: Boolean, default: false
    }
  }

  connect() {
    this.radioTargets.forEach(radio => {
      if (radio.checked) {
        this.changeElement(radio)
      }
    });
  }

  toggleDestination(element, isShow) {
    element.classList.toggle('hidden', !isShow)
    element.classList.toggle('disabled', !isShow)

    this.disableInputs(element, isShow)
  }

  disableInputs(element, isShow) {
    let disabled = this.shouldDisable(element, isShow);

    const inputs = element.querySelectorAll('select, input, textarea')
    inputs.forEach(input => {
      input.disabled = disabled
      input.classList.toggle('disabled', disabled)
    })
  }

  shouldDisable(element, isShow) {
    if (this.disableIfParentHiddenValue) {
      return !element.checkVisibility()
    } else {
      return !isShow
    }
  }

  changeElement(element) {
    const selected = element.value
    this.destinationTargets.forEach(element => {
      const value = element.getAttribute('data-value')
      this.toggleDestination(element, selected == value);
    })
  }

  change(event) {
    this.changeElement(event.target)
  }
}
